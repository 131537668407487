const Rights = () => {
  return (
    <section className="w-full flex justify-center px-2">
      <div className="bg-[#ffffffc4] bg-clip-padding mb-10 border-2 border-[#808080] z-20 backdrop-filter backdrop-blur-lg max-w-screen-xl flex flex-col items-center w-full rounded-xl mt-32 bg-white pb-10 sm:pb-20 px-5 sm:px-10">
        <h2 className="text-xl sm:text-3xl font-bold text-center mt-14 mb-10">
          Toon City NFT Ownership and Rights Agreement
        </h2>
        <p className="max-w-4xl text-md sm:text-xl text-center font-semibold mb-4 sm:mb-10">
          This Ownership and Rights Agreement (the "Agreement") is entered into
          as of the date of purchase (the "Effective Date") by and between Toon
          City NFT ("Seller"), and the holder of the relevant Non-Fungible Token
          ("NFT") on the Ethereum blockchain ("Buyer"). This Agreement pertains
          to the Toon City NFT project (the "Project") and establishes the
          rights and responsibilities of the Buyer in relation to the Project.
        </p>

        <div className="flex flex-col w-full sm:max-w-[80%]">
          {/* 1 - sect */}
          <h3 className="font-bold text-xl mt-10 mb-3">
            1. Ownership and Rights
          </h3>
          <p className="mb-2">
            <b>1.1.</b> By purchasing and holding the NFT associated with a
            specific digital art piece (the "Artwork") within the Project, Buyer
            is granted the exclusive ownership and all intellectual property
            rights, including but not limited to copyright, in and to the
            Artwork.
          </p>
          <p className="mb-2">
            <b>1.2.</b> Buyer shall have the unrestricted right to reproduce,
            distribute, publicly display, publicly perform, create derivative
            works from, and otherwise exploit the Artwork for both commercial
            and non-commercial purposes.
          </p>
          <p className="mb-2">
            <b>1.3.</b> Buyer shall have the right to license, sublicense, sell,
            resell, or otherwise transfer or assign any or all of the rights
            granted herein, in whole or in part, to any third party.
          </p>
          {/* 2 - sect */}
          <h3 className="font-bold text-xl mt-10 mb-3">
            2. Representations and Warranties
          </h3>
          <p className="mb-2">
            <b>2.1.</b> Seller represents and warrants that they are the sole
            creator and owner of the Artwork, and have the full right, power,
            and authority to enter into this Agreement and to grant the rights
            provided herein.
          </p>
          <p className="mb-2">
            <b>2.2.</b> Seller represents and warrants that the Artwork does not
            infringe upon or violate any intellectual property rights, including
            copyrights, trademarks, or trade secrets, of any third party.
          </p>
          {/* 3 - sect */}
          <h3 className="font-bold text-xl mt-10 mb-3">3. Indemnification</h3>
          <p className="mb-2">
            <b>3.1.</b> Seller agrees to indemnify, defend, and hold harmless
            Buyer from and against any and all claims, liabilities, damages,
            costs, and expenses (including reasonable attorneys' fees) arising
            out of or relating to any breach or alleged breach of the
            representations and warranties made by Seller in this Agreement.
          </p>
          {/* 4 - sect */}
          <h3 className="font-bold text-xl mt-10 mb-3">
            4. Governing Law and Dispute Resolution
          </h3>
          <p className="mb-2">
            <b> 4.1.</b> This Agreement shall be governed by and construed in
            accordance with the laws of the State of California, United States,
            without regard to its conflicts of law principles.
          </p>
          <p className="mb-2">
            <b> 4.2.</b> Any dispute, controversy, or claim arising out of or in
            connection with this Agreement shall be settled through negotiation
            between the parties or, failing such negotiation, by arbitration in
            accordance with the rules of the American Arbitration Association in
            force at the time of the arbitration, which rules are deemed to be
            incorporated by reference in this clause.
          </p>
          {/* 4 - sect */}
          <h3 className="font-bold text-xl mt-10 mb-3">5. Miscellaneous</h3>
          <p className="mb-2">
            <b> 5.1.</b> This Agreement constitutes the entire understanding and
            agreement between the parties with respect to the subject matter
            hereof and supersedes all prior and contemporaneous agreements,
            whether written or oral, relating to the subject matter.
          </p>
          <p className="mb-2">
            <b>5.2.</b> If any provision of this Agreement is found to be
            invalid or unenforceable, the remaining provisions shall continue in
            full force and effect.
          </p>
          <p className="mb-2">
            <b>5.3.</b>This Agreement is automatically effective upon the
            purchase of the relevant NFT, and no physical or digital signature
            is required for the Agreement to be binding.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Rights;
