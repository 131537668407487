import email from "../../assets/Icons/email.png";
import facebook from "../../assets/Icons/facebook.png";
import twitter from "../../assets/Icons/twitter.png";
import openSea from "../../assets/Icons/opensea.png";

const Footer = () => {
  return (
    <footer className="w-full p-4 flex justify-center">
      <div className="flex flex-col py-3 items-center bg-white rounded-lg border-[#111] border-2 px-5">
        <h2 className="text-2xl mb-2">Contact us at:</h2>
        <ul className="flex gap-4">
          <li>
            <a
              href="mailto:tooncitynft@proton.me"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={email}
                alt="email"
              />
            </a>
          </li>

          <li>
            <a
              href="https://twitter.com/ToonCityNFT/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={twitter}
                alt="twitter"
              />
            </a>
          </li>
          <li>
            <a
              href="https://opensea.io/collection/tooncity"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={openSea}
                alt="openSea"
                className="w-14"
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
