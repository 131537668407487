import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header";

import Rights from "./Rights";
import About from "./About";
import Stake from "./Stake";
import Footer from "./components/Footer";
import { Buffer } from "buffer/";
window.Buffer = window.Buffer || Buffer;

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="/"
            element={<Stake />}
          />
          <Route
            path="/stake"
            element={<Stake />}
          />
          <Route
            path="/about"
            element={<About />}
          />
          <Route
            path="/rights"
            element={<Rights />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
