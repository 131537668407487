import { useWeb3Modal } from "@web3modal/react";
import { NavLink } from "react-router-dom";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { addressShortener, longAddress } from "../../utils";

const Header = () => {
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { address, isConnected } = useAccount();

  const connectWallet = () => {
    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header className="w-full flex justify-center pt-1 px-1">
      <div className="max-w-screen-2xl w-full p-2 rounded-xl">
        {/* Desktop header */}

        <ul className="flex w-full mb-3 justify-center items-center gap-1 md:hidden">
          <li>
            <NavLink
              to="/stake"
              className={({ isActive }) =>
                isActive
                  ? "py-2 px-3 text-[#090909] font-semibold bg-[#ffcb11] border-2 border-[#1e1e1e] shadow-md text-sm rounded-xl"
                  : "py-2 px-3 text-[#262626] font-semibold bg-[#ffe06e] border-2 border-[#696969] shadow-md text-sm rounded-lg"
              }
            >
              Stake
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "py-2 px-3 text-[#090909] font-semibold bg-[#0affd6] border-2 border-[#1e1e1e] shadow-md text-sm rounded-xl"
                  : "py-2 px-3 text-[#262626] font-semibold bg-[#79fdff] border-2 border-[#696969] shadow-md text-sm rounded-lg"
              }
            >
              About
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/rights"
              className={({ isActive }) =>
                isActive
                  ? "py-2 px-3 text-[#090909] font-semibold bg-[#0affd6] border-2 border-[#1e1e1e] shadow-md text-sm rounded-xl"
                  : "py-2 px-3 text-[#262626] font-semibold bg-[#79fdff] border-2 border-[#696969] shadow-md text-sm rounded-lg"
              }
            >
              Rights
            </NavLink>
          </li>
        </ul>

        <div className="flex justify-center md:justify-between items-center">
          <div className="flex gap-4">
            <ul className="hidden md:flex items-center gap-2">
              <li>
                <NavLink
                  to="/stake"
                  className={({ isActive }) =>
                    isActive
                      ? "py-2 px-5 text-[#090909] font-semibold bg-[#ffcb11] border-2 border-[#1e1e1e] shadow-md text-xl rounded-xl"
                      : "py-2 px-5 text-[#262626] font-semibold bg-[#ffe06e] border-2 border-[#696969] shadow-md text-xl rounded-lg"
                  }
                >
                  Stake
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive
                      ? "py-2 px-5 text-[#090909] font-semibold bg-[#0affd6] border-2 border-[#1e1e1e] shadow-md text-xl rounded-xl"
                      : "py-2 px-5 text-[#262626] font-semibold bg-[#79fdff] border-2 border-[#696969] shadow-md text-xl rounded-lg"
                  }
                >
                  About
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/rights"
                  className={({ isActive }) =>
                    isActive
                      ? "py-2 px-5 text-[#090909] font-semibold bg-[#0affd6] border-2 border-[#1e1e1e] shadow-md text-xl rounded-xl"
                      : "py-2 px-5 text-[#262626] font-semibold bg-[#79fdff] border-2 border-[#696969] shadow-md text-xl rounded-lg"
                  }
                >
                  Rights
                </NavLink>
              </li>
            </ul>
          </div>

          {isConnected ? (
            <div>
              <button className="hidden md:flex bg-[#FC598E] border-2 border-[#4f4f4f] p-3 rounded-lg">
                {addressShortener(address)}
              </button>
              <button className="flex md:hidden bg-[#FC598E] border-2 border-[#4f4f4f] p-3 rounded-lg">
                {longAddress(address)}
              </button>
            </div>
          ) : (
            <div>
              <div className="hidden md:flex">
                <button
                  className="bg-[#FC598E] border-2 border-[#4f4f4f] p-3 rounded-lg"
                  onClick={connectWallet}
                >
                  Connect Wallet
                </button>
              </div>
              <div className="md:hidden">
                <button
                  className="bg-[#FC598E] border-2 border-[#4f4f4f] p-3 rounded-lg"
                  onClick={connectWallet}
                >
                  Connect{" "}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
