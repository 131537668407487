import { useWeb3Modal } from "@web3modal/react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import nftAbi from "./contracts/nft_abi.json";
import search from "./assets/Icons/search.png";
import stakeAbi from "./contracts/stake_abi.json";

const Stake = () => {
  const [userBalance, setUserBalance] = useState(0);
  const [tokenRewards, setTokenRewards] = useState(0);
  const [checkId, setCheckId] = useState();
  const [idCollection, setIdCollection] = useState([]);
  const [checkStakedId, setCheckStakedId] = useState();

  const { data: signer } = useSigner();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();

  const nftAddress = "0x295aa8fEd0C5049dC4A84D10725EA640efe87A34";
  const stakeAddress = "0x8eec9569dd7eef173ccb4958542838f20a3decbb";

  const staticProvider = new ethers.providers.JsonRpcProvider(
    "https://rpc.ankr.com/eth"
  );

  const readNftContract = new ethers.Contract(
    nftAddress,
    nftAbi,
    staticProvider
  );

  const readStakeContract = new ethers.Contract(
    stakeAddress,
    stakeAbi,
    staticProvider
  );

  const stakeContract = new ethers.Contract(stakeAddress, stakeAbi, signer);

  useEffect(() => {
    const fetchInitialData = async () => {
      const nftBalance = await readNftContract.balanceOf(address);

      setUserBalance(ethers.utils.formatUnits(nftBalance, 0));
    };

    fetchInitialData();
  }, []);

  const connectWallet = () => {
    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const stakeNfts = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    try {
      const stakeNfts = await stakeContract.stakeMultiple(
        nftAddress,
        idCollection.map((item) => Number(item))
      );

      await stakeNfts.wait();
      alert("success!");
    } catch (error) {
      console.log(error);
    }
  };

  const unstakeNfts = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    try {
      const stakeNfts = await stakeContract.unstakeMultiple(
        nftAddress,
        idCollection.map((item) => Number(item))
      );

      await stakeNfts.wait();
      alert("success!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setIdCollection(value.split(","));
  };

  const fetchReward = async () => {
    try {
      const rewards = await readStakeContract.pendingRewardNew(
        nftAddress,
        checkId
      );

      setTokenRewards(ethers.utils.formatUnits(rewards, 0));
    } catch (error) {
      alert(error);
    }
  };

  const checkStaked = async () => {
    try {
      const staked = await readStakeContract.tokenIdIsStaked(
        nftAddress,
        checkStakedId
      );

      alert(
        staked
          ? `Token ${checkStakedId} is staked`
          : `Token ${checkStakedId} is NOT staked`
      );
    } catch (error) {
      alert(error);
    }
  };

  const handleCheckStaked = (e) => {
    setCheckStakedId(e.target.value);
  };

  const harvestRewardById = async () => {
    try {
      const rewards = await stakeContract.claimRewardsNew(nftAddress, checkId);
      await rewards.wait();
      alert("rewards claimed!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckInput = (e) => {
    setCheckId(e.target.value);
  };

  return (
    <section className="w-full mb-10 flex justify-center items-center px-2">
      <div className="max-w-screen-sm w-full border-black border-[3px] mt-52 p-10 rounded-2xl flex flex-col items-center bg-[#fffffff5]">
        <h2 className="text-2xl text-center mb-3">
          Buy, Stake, Harvest, Repeat!
        </h2>

        <div className="flex gap-2 mb-4">
          <div className="flex bg-[#ebebeb] border-2 p-2 rounded-lg text-lg flex-col items-center">
            <h4>Balance</h4>
            <p>{userBalance}</p>
          </div>
          <div className="flex bg-[#ebebeb] border-2 p-2 rounded-lg text-lg flex-col items-center">
            <h4>Check staked</h4>
            <div className="flex gap-1 items-center justify-center">
              <input
                onChange={handleCheckStaked}
                type="number"
                min={0}
                className="bg-[#3f3f3f] appearance-none w-14 text-white text-center rounded-xl h-10"
              />
              <button
                onClick={checkStaked}
                className="bg-white border p-2 rounded-lg"
              >
                <img
                  src={search}
                  alt="search"
                  className="w-5"
                />
              </button>
            </div>
          </div>
        </div>

        <h3 className="bg-[#515151] text-white rounded mb-1 p-1">
          Check Rewards by Token id
        </h3>
        <input
          onChange={handleCheckInput}
          type="number"
          min={0}
          className="bg-[#3f3f3f]  text-white text-center rounded-xl h-10 mb-2"
        />

        {/* Rewards */}
        <div className="flex gap-2">
          <button
            onClick={harvestRewardById}
            className="p-2 bg-green-300 rounded-lg mb-2"
          >
            Claim
          </button>
          <button
            onClick={fetchReward}
            className="p-2 bg-[#e1ef27] rounded-lg mb-2"
          >
            Check rewards
          </button>
        </div>
        <h3 className="mb-7">Total Rewards: {tokenRewards}</h3>

        <input
          onChange={handleInputChange}
          type="text"
          className="bg-[#212121] text-white text-center rounded-xl h-10 mb-1"
        />
        <h3 className="text-sm text-red-500 mb-2">
          IDs have to be comma separeted
        </h3>
        {/* Buttons */}
        <div className="flex gap-2">
          <button
            onClick={stakeNfts}
            class="bg-[#FFE486] hover:bg-[#ffda56] text-[#212121] font-bold py-2 px-4 border-b-4 border-[#fdc703] rounded"
          >
            Stake
          </button>
          <button
            onClick={unstakeNfts}
            class="bg-[#FFE486] hover:bg-[#ffda56] text-[#212121] font-bold py-2 px-4 border-b-4 border-[#fdc703] rounded"
          >
            Unstake
          </button>
        </div>
      </div>
    </section>
  );
};

export default Stake;
